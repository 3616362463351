
import Vue from "vue"
import ClickOutside from "vue-click-outside"
import { getImagesAsset, getDemoAsset, checkDomain } from "@/config/util"
import subscriptionStatusMixin from "@/mixins/subscriptionStatusMixin"
import SvgIcon from "@/components/base/SvgIcon/SvgIcon.vue"
import BlockPopupInvite from "@/components/blocks/BlockPopup/BlockPopupInvite.vue"
import { mapState } from "vuex"
import notificationSoundFile from "@/assets/sound/new-message.mp3"

interface Data {
  placeholderImage: string
  notificationImage: string
  notificationNum: number
  active: boolean
  pageCheck: boolean
  showPopup: boolean
  ExplanationVideoPopupActive: boolean
  prevNotificationCount: number | null
  notificationSound: HTMLAudioElement | null
  initialLoadComplete: boolean
}

export default Vue.extend({
  name: "NotificationComponent",

  components: {
    SvgIcon,
    BlockPopupInvite,
  },

  directives: {
    ClickOutside,
  },
  mixins: [subscriptionStatusMixin],

  data(): Data {
    return {
      placeholderImage: getImagesAsset("placeholders/user_placeholder.png"),
      notificationImage: getDemoAsset("profile/Notification_logo.svg"),
      notificationNum: 17,
      active: false,
      pageCheck: false,
      showPopup: false,
      ExplanationVideoPopupActive: false,
      prevNotificationCount: null, // Initialize as null to prevent unintended comparisons
      notificationSound: null,
      initialLoadComplete: false, // Flag to indicate initial load completion
    }
  },

  computed: {
    isTrialing() {
      return this.$store.getters.subscriptionIsTrial
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    image() {
      return this.$store.getters.getProfileImage
    },
    subscription() {
      return this.$store.state.Broker.subscription
    },
    subscriptionName() {
      let plan = this.$store.state.Broker.subscription
      if (this.isActiveSubscription(plan?.status)) {
        return plan?.name
      } else if (this.isTrialingSubscription(plan?.status)) {
        return `${plan?.name} - Free`
      } else {
        return "Free tier"
      }
    },
    daysRemaining() {
      const today = new Date()
      const endsAt = new Date(this.subscription?.ends_at)

      const diff = endsAt.getTime() - today.getTime()
      const days = Math.round(diff / (1000 * 3600 * 24))

      return days > 1 ? `${days} days` : `${days} day`
    },

    ...mapState({
      non_beta_user: (state) => state.User.non_beta_user,
      unseenMessages: (state) => state.Chat.chat.unseenMessages,
    }),
    getPlayNotificationSound() {
      return this.$store.getters.getPlayNotificationSound
    },
  },

  watch: {
    getPlayNotificationSound(val: boolean) {
      if (val) {
        this.$store.dispatch("playNotificationSound", false)
        this.playNotificationSound()
      }
    },
    unseenMessages: {
      handler(newVal: { count: number }) {
        console.log("Watcher triggered: unseenMessages changed to", newVal)
        if (!this.initialLoadComplete) {
          // Do not play sound or send notification on initial load
          console.log("Initial load detected. Sound and notification will not play.")
        } else if (newVal.count > (this.prevNotificationCount || 0)) {
          console.log("New unseen messages detected. Playing sound and sending notification...")
          this.playNotificationSound()
          this.sendBrowserNotification("New Message", {
            body: `You have ${newVal.count} new message(s).`,
            icon: this.notificationImage, // Optional: Add an icon
            // You can add more options like `vibrate` or `tag` if needed
          })
        }
        this.prevNotificationCount = newVal.count
        this.updateDocumentTitle(newVal)
      },
      deep: true,
      immediate: true, // Keep immediate to handle updates promptly
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch("getUnseenMessages").then(() => {
        this.prevNotificationCount = this.unseenMessages.count
        console.log("Initial unseenMessages count:", this.prevNotificationCount)
        this.initialLoadComplete = true // Set flag after initial load
      })
    }

    // Initialize notification sound
    this.notificationSound = new Audio(notificationSoundFile)
    this.notificationSound.preload = "auto"

    // Request notification permission
    this.requestNotificationPermission()

    // Listen for logout event
    this.$root.$on("logout-beta", this.logout)
  },

  beforeDestroy() {
    this.$root.$off("logout-beta", this.logout)
  },

  methods: {
    async handleManageMySubscription() {
      try {
        const response = await this.$store.dispatch("getSparkUrl", {
          return_url: `${window.location.origin}${window.location.pathname}`,
        })
        window.location.href = response.data.data.sparkLogin.success
      } catch (error) {
        console.error("Error managing subscription:", error)
      }
    },

    playNotificationSound() {
      const currentPath = this.$route.path
      const excludedPaths = ["/lender-messages", "/broker-messages"]

      if (!this.active && !excludedPaths.includes(currentPath) && this.notificationSound) {
        this.notificationSound
          .play()
          .then(() => {
            console.log("Notification sound played successfully.")
          })
          .catch((error) => {
            console.error("Sound play failed:", error)
          })
      } else {
        console.log(`Sound not played. Current path: ${currentPath}`)
      }
    },

    clickOutside(event: Event) {
      if (this.$refs.video && !(this.$refs.video as HTMLElement).contains(event.target as Node)) {
        this.ExplanationVideoPopupActive = false
      }
    },

    ExplanationVideo() {
      this.ExplanationVideoPopupActive = true
      this.$store.dispatch("explanationVideoFromhamburgerMenuClicked", false)
    },

    popupClose() {
      this.ExplanationVideoPopupActive = false
      this.$store.dispatch("explanationVideoFromhamburgerMenuClicked", false)
    },

    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {
          if (process.env.VUE_APP_GTM_ENVIRONMENT === "production") {
            const auth = JSON.parse(localStorage.getItem("auth") || "{}")
            window["dataLayer"] = window["dataLayer"] || []
            window["dataLayer"].push({
              event: "logout",
              userId: auth?.user?.id,
            })
          }

          localStorage.removeItem("auth")
          localStorage.removeItem("chatbot")
          window.location.href = "/login"
        })
        .catch((error: any) => {
          console.error("Error during logout:", error)
        })
    },

    hide() {
      this.active = false
    },

    clearToken() {
      if (this.$router.currentRoute.path === "/sign-up/lender/2") {
        const token = localStorage.getItem("tempToken")
        if (token !== null) {
          localStorage.removeItem("tempToken")
        }
      }
    },

    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
    },
    goTo() {
      if (this.$route.path !== "/profile") {
        this.$router.push({ path: "/profile" })
      }
    },

    logoChange(): boolean {
      return checkDomain()
    },

    updateDocumentTitle(unseenMessages: { count: number }) {
      const count = unseenMessages?.count || 0
      console.log("Updating document title with count:", count)
      if (count > 0) {
        document.title = `(${count}) New Messages`
        this.changeFavicon("/faviconnotification.ico")
      } else {
        document.title = "Finance Lobby - Meet Your Perfect Deal"
        this.changeFavicon("/favicon.ico")
      }
    },
    changeFavicon(href: string) {
      let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement
      if (link) {
        link.href = `${href}?v=${new Date().getTime()}`
      } else {
        link = document.createElement("link")
        link.rel = "shortcut icon"
        link.href = `${href}?v=${new Date().getTime()}`
        document.head.appendChild(link)
      }
    },

    /**
     * Requests permission from the user to send browser notifications.
     */
    requestNotificationPermission() {
      if (!("Notification" in window)) {
        console.error("This browser does not support desktop notifications.")
        return
      }

      if (Notification.permission === "default") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.")
          } else if (permission === "denied") {
            console.log("Notification permission denied.")
          }
          // No action needed if permission is already granted or denied
        })
      }
    },

    /**
     * Sends a browser notification with the given title and options.
     * @param title The title of the notification.
     * @param options Optional notification options such as body, icon, etc.
     */
    // eslint-disable-next-line no-undef
    sendBrowserNotification(title: string, options?: NotificationOptions) {
      if (Notification.permission === "granted") {
        const notification = new Notification(title, options)
        notification.onclick = () => {
          window.focus()
          this.$router.push("/chat") // Adjust the path as needed
          notification.close()
        }
      } else {
        console.warn("Notification permission not granted.")
      }
    },
  },
})
